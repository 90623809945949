import React from "react";
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { Typography, H2, P } from 'style';
import { Head } from 'components';
import {
  backgroundGray,
  desktopWidth,
  mobileHdWidth,
  paddingWrapperSm,
  paddingWrapperMd,
  paddingWrapperXl,
  paddingWrapperXxl,
  tabletWidth,
  white,
} from 'style/constants';
import renderHTML from 'react-render-html';

const Container = styled.div`
margin: auto;

max-width: 850px;
  display: flex;
  flex-direction: column;
  padding: ${paddingWrapperXxl} ${paddingWrapperMd};
  box-sizing: border-box;
  background-color: ${props => (props.dark ? backgroundGray : white)};

  @media (max-width: ${tabletWidth}) {
      width: 100%;
    max-width: 850px;
    padding-top: ${paddingWrapperXl};
    padding-bottom: ${paddingWrapperXl};
  }

  @media (max-width: ${mobileHdWidth}) {
    width: 100%;
    max-width: 850px;
    padding-left: ${paddingWrapperSm};
    padding-right: ${paddingWrapperSm};
  }
`;

const Wrapper = styled.div`
  justify-content: center;
  width: 100%;
  max-width: ${desktopWidth};
`;

const Header = styled.header`
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: ${paddingWrapperMd};

  @media (max-width: ${tabletWidth}) {
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;


  padding-top: ${paddingWrapperXl};
  padding-bottom: ${paddingWrapperXl};
}

@media (max-width: ${mobileHdWidth}) {
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;


  padding-left: 0%;
  padding-right: 0%;
}
`;

export const query = graphql`
  {
    prismicPage(uid: {eq: "privacy"}) {
      data {
        body {
          html
          text
        }
        subtitle {
          html
          text
        }
        title {
          html
          text
        }
      }
    }
  }
`
const Page = ({ data }) => {
  return (
    <React.Fragment>
      <Head title="Privacy" />
      <Container>
        <Wrapper>
          <Header>
            <H2>{data.prismicPage.data.title.text}</H2>
            {data.prismicPage.data.subtitle.text && <P>{data.prismicPage.data.subtitle.text}</P>}
          </Header>
          <Typography>
            {renderHTML(data.prismicPage.data.body.html)}
          </Typography>
        </Wrapper>
      </Container>
    </React.Fragment>
  )
}
export default Page